import React, { useState, useEffect, useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import AnimationLink from "./animation-link";
import styled from "styled-components";
import ArrowIcon from "./icons/arrow-icon";
import CurveIcon from "./icons/curve-icon";
import theme from "../styles/theme";
import media from "../styles/media";
const { colors } = theme;

export default (props) => (
  <StaticQuery
    query={graphql`
      query HeadMenuQuery {
        HeadMenu: contentfulItemList(
          contentful_id: { eq: "2wbrhDGvpvJzF50W0KCkXi" }
        ) {
          __typename
          contentful_id
          name
          item {
            ...GlobalItemFragment
          }
        }
      }
    `}
    render={({ HeadMenu }) => {
      return <Nav menuItem={HeadMenu} {...props} />;
    }}
  />
);

const menuItems = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Work",
    link: "/work",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Start a project",
    link: "/start-a-project",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const Nav = ({ pageLayoutRef }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navContentRef = useRef();

  useEffect(() => {
    if (menuOpen) {
      document.querySelector("body").style.maxHeight = "100vh";
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.maxHeight = "unset";
      document.querySelector("body").style.overflow = "unset";
    }
    return () => {};
  }, [menuOpen]);

  return (
    <>
      <StyledMenuLayout menuOpen={menuOpen} ref={navContentRef}>
        <Container>
          <Sidebar>
            <StyledList>
              {menuOpen &&
                menuItems &&
                menuItems.slice(0, 4).map(({ link, name }, index) => (
                  <StyledListItem key={index}>
                    <AnimationLink
                      navLink={link}
                      coverContentRef={navContentRef}
                      pageLayoutRef={pageLayoutRef}
                    >
                      <StyledListLink>{name}</StyledListLink>
                    </AnimationLink>
                  </StyledListItem>
                ))}
            </StyledList>
            <StyledRightList>
              {menuOpen &&
                menuItems &&
                menuItems.slice(4, 6).map(({ link, name }, index) => (
                  <StyledRightListItem key={index}>
                    <AnimationLink
                      navLink={link}
                      coverContentRef={navContentRef}
                      pageLayoutRef={pageLayoutRef}
                    >
                      <StyledListLink to={link}>{name}</StyledListLink>
                    </AnimationLink>
                    <ArrowIcon />
                  </StyledRightListItem>
                ))}
            </StyledRightList>
          </Sidebar>
          <StyledCurveIcon>
            <CurveIcon />
          </StyledCurveIcon>
        </Container>
      </StyledMenuLayout>
      <StyledToggleMagnetize menuOpen={menuOpen}>
        <StyledMenuIcon onClick={() => setMenuOpen(!menuOpen)}>
          <StyledFirstLine menuOpen={menuOpen} />
          <StyledSecondLine menuOpen={menuOpen} />
        </StyledMenuIcon>
      </StyledToggleMagnetize>
    </>
  );
};

const StyledToggleMagnetize = styled.div`
  position: fixed;
  top: 17px;
  right: 3px;
  width: 100px;
  height: 100px;
  display: block;
  padding-left: 20px;
  padding-top: 40px;
  z-index: 9999;
  ${media.tablet`padding-left: 40px; top: 0px;`};
`;

const StyledMenuIcon = styled.div`
  cursor: pointer;
`;
const StyledFirstLine = styled.div`
  width: 41px;
  border-top: 2px solid #000;
  margin-bottom: 10px;
  margin-left: 10px;
  transition-duration: 0.3s;
  ${({ menuOpen }) =>
    menuOpen && `transform: rotate(45deg); border-color: white;`};
`;

const StyledSecondLine = styled.div`
  width: 41px;
  border-top: 2px solid #000;
  transition-duration: 0.3s;
  ${({ menuOpen }) =>
    menuOpen &&
    `transform: translate(10px, -12px) rotate(-45deg); border-color: white;`};
`;

const StyledMenuLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  transition: ${theme.transition};
  background-color: ${colors.darkGrey};
  transform: translateY(${(props) => (props.menuOpen ? 0 : "-100%")});
  visibility: ${(props) => (props.menuOpen ? "visible" : "hidden")};
  overflow: scroll;
  z-index: 999;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.darkGrey};
  display: flex;
  align-items: center;
`;

const StyledList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  ${media.desktop`margin-left: 0px;`};
  ${media.tablet`margin-left: 20px;`};
`;

const StyledListItem = styled.li`
  margin: 32px 0px;
  position: relative;
  counter-increment: item 1;
  width: max-content;
  font-style: normal;
  font-weight: 300;
  font-size: 4rem;
  line-height: 91.6%;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    transform: translateX(24px);
    transition-duration: 0.3s;
    ${media.tablet`transform: translateX(24px);`};
  }
  ${media.bigDesktop`font-size: 3rem;`};
  ${media.desktop`font-size: 2.5rem;`};
  ${media.tablet`font-size: 2.5rem;`};
`;

const StyledListLink = styled.span`
  text-decoration: none;
  color: ${colors.darkTurquoise};
  padding-right: 16px;
  ${media.tablet`padding-right: 8px;`};
`;

const StyledListExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.darkTurquoise};
  padding-right: 16px;
  ${media.tablet`padding-right: 8px;`};
`;

const StyledRightList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  ${media.desktop`margin-top: 64px; margin-right: 20px; width: fit-content;`};
  ${media.tablet`
    margin-top: 63px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    width: 100%;
  `};

  ${media.thone`
    margin-top: 42px;
  `};
`;

const StyledRightListItem = styled.li`
  font-style: normal;
  font-weight: 200;
  font-size: 2rem;
  line-height: 108.1%;
  margin: 32px 0px;
  display: flex;
  align-items: center;
  svg {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
    ${media.tablet`height: 30px;`};
  }
  &:hover {
    svg {
      transform: translateX(24px);
    }
  }
  ${media.bigDesktop`font-size: 1.8rem; min-width: 320px; margin: 8px 0px;`};
  ${media.tablet`font-size: 1.5rem; min-width: 320px; margin: 8px 0px;`};
`;

const Sidebar = styled.aside`
  background-color: ${colors.darkGrey};
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  ${media.thone`display: block;`};
`;

const StyledCurveIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 102px;
  height: 70px;
  ${media.desktop`margin-top: 230px;`};
  ${media.tablet`margin-top: 43px; width: 82px;`};
`;
