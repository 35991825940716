import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import socialSharingImage from "../images/seo.png";

const SEO = ({ data, location }) => {
  const { pageUrl, pageTitle, indexibility, metaDescription } = data;
  const noIndexOption =
    indexibility === "Non-indexable"
      ? false
      : indexibility === "Indexable"
      ? true
      : null;
  const title = pageTitle;

  const description = metaDescription ? metaDescription : null;
  const ogTagImage = socialSharingImage;

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: location?.origin + pageUrl,
      name: title,
      alternateName: "vello",
      structuredData: {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name: "vello",
      },
    },
  ];

  const robot = getRobot(noIndexOption);

  return (
    <Helmet>
      {/* <link rel="icon" href={favicon} /> */}
      {/* General tags */}
      <link rel="canonical" href={`${location?.origin}`} />
      <title>{title}</title>
      {/* <meta name="image" content={image} /> */}
      <meta name="description" content={description} />
      {robot}
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <html lang="en" />
      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogTagImage} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={"vello"} />
      <meta property="og:url" content={`${location?.origin}${pageUrl}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;

const getRobot = (indexable) => {
  if (indexable === false || indexable === null) {
    return <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />;
  } else {
    return <meta name="ROBOTS" content="FOLLOW,INDEX" />;
  }
};

// Fragment for the Services Card content type
export const globalSEOFragment = graphql`
  fragment GlobalSEOFragment on Node {
    ... on ContentfulGlobalSeo {
      __typename
      contentful_id
      name
      pageUrl
      pageTitle
      indexibility
      socialSharingImage {
        file {
          url
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`;
